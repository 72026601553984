@import "../theme/mixins";

$brand: 'avec';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/fonts/Raleway-Medium.ttf");
}
@font-face {
  font-family: AppFontSemiBold;
  src: url("../assets/fonts/Raleway-SemiBold.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/Raleway-Bold.ttf");
}
@font-face {
  font-family: AppFontExtraBold;
  src: url("../assets/fonts/Raleway-ExtraBold.ttf");
}
@font-face {
  font-family: AppFontBlack;
  src: url("../assets/fonts/Raleway-Black.ttf");
}


:root {
  --brand: $brand;
	--ion-color-primary: #046069;
	--ion-color-primary-rgb: 4,96,105;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #04545c;
	--ion-color-primary-tint: #1d7078;

	--ion-color-secondary: #046069;
	--ion-color-secondary-rgb: 4,96,105;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #04545c;
	--ion-color-secondary-tint: #1d7078;

  --ion-color-tertiary: #000000;
  --ion-color-tertiary-rgb: 0, 0, 0;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #000000;
  --ion-color-tertiary-tint: #1a1a1a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

  --ion-color-medium: #393939;
	--ion-color-medium-rgb: 57,57,57;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #323232;
	--ion-color-medium-tint: #4d4d4d;


  --ion-color-light: #B0B0B0;
	--ion-color-light-rgb: 176,176,176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

  --ion-font-family: AppFont, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
  --ion-color-title: var(--ion-color-primary);
  --black-title: #000000;
  --black-subtitle: #333333;
  --background-default: #F1E8DF;
  --white-default: #FFFFFF;
  --icon-color: #F1E8DF;
  --fill: red;
}

h1, h2, h3, h4, h5, h6 {
  font-family: AppFontExtraBold, Helvetica, sans-serif;
  color: var(--black-title);
  font-size: 16px;
  margin: 0;
  font-weight: unset;
}

p, span {
  font-family: AppFont, Helvetica, sans-serif;
  color: var(--black-subtitle);
  font-size: 16px;
  padding: 0;
  margin: 0;
}

.ion-page,
ion-footer,
ion-content {
  background: white;
  --background: white;
}
app-menu-modal {
  background: var(--ion-color-primary) url(#{$brandAssets}/menu-modal.svg) no-repeat right bottom / 50% !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal {
  background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;
  --background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal,
app-payment-success {
  background: var(--ion-color-primary) !important;
  --background: var(--ion-color-primary) !important;
  // background: white url(../assets/olearys/background_payment.png) no-repeat center center / 100% 100% !important;
  // --background: white url(../assets/olearys/background_payment.png) no-repeat center center / 100% 100% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home {
  background:  var(--background-default) ;
  // background-size: cover !important;

  @include mobile {
    background: var(--background-default) url(#{$brandAssets}/home_mobile.jpg) no-repeat center top / 100% 100% !important;
  }

  ion-header {
    background: var(--background-default);
  }

  ion-content {
    background: var(--background-default) !important;
    --background: var(--background-default) !important;
    
  @include mobile {
    background: var(--background-default)  url(#{$brandAssets}/home_mobile.jpg) no-repeat center top / 100% 100% !important;
    --background: var(--background-default)  url(#{$brandAssets}/home_mobile.jpg) no-repeat center top / 100% 100% !important;
  }
  }
  ion-footer {
    background: transparent !important;
    --background: transparent !important;
  }
}

app-order,
app-payment-modal {
	background: var(--background-default) !important;

	// background-size: 100% 30vh !important;
	// background-position: center top;

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

app-checkout-modal {
	background: var(--background-default) !important;
	--background: var(--background-default) !important;

	// background-size: 100% 30vh !important;
	// background-position: center top;

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

app-email-action,
app-sign-in,
app-sign-in-order,
app-sign-up,
app-maintenance-page,
app-email-confirmation {
	background: var(--background-default) !important;
	--background: var(--background-default) !important;
  
	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

